<template>
  <van-swipe
    :autoplay="autoplay"
    class="my-swipe"
    @change="onChange"
    ref="swiperitem"
  >
  
    <van-swipe-item v-for="(image, index) in final_img" :key="index">
      <video
        v-if="image.goodsimage_type == 2"
        controls
        autoplay
        webkit-playsinline="true"
        playsinline="true"
        :poster="image.goodsvideo_index"
        :src="image.goodsimage_full_url"
        @click="stop"
        class="video"
        muted
        loop
      ></video>
      <img v-lazy="image.goodsimage_full_url || image.goodsimage_url" v-else />
    </van-swipe-item>

    <template #indicator>
      <div class="custom-indicator">
        {{ current + 1 }}/{{ imgs && imgs.length }}
      </div>
    </template>
  </van-swipe>
</template>

<script>
import { Swipe, SwipeItem } from "vant";

export default {
  data() {
    return {
      current: 0,
      autoplay: 4000,
      final_img: [],
    };
  },
  props: {
    imgs: {
      type: Array,
    },
  },
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
  },
  created() {
   this.init()
  },
  mounted() {
    let that = this;

    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     let v = document.querySelectorAll("video");
    //     if (v && v.length != 0) {
    //       for (let index = 0; index < v.length; index++) {
    //         v[index].addEventListener("play", function () {
    //           that.autoplay = 0;
    //         });
    //         v[index].addEventListener("pause", function () {
    //           that.autoplay = 0;
    //         });
    //       }
    //     }
    //   }, 500);
    // });
  },
  watch: {
    imgs() {
     this.init()
    },
  },
  methods: {
    init(){
       if (this.imgs && this.imgs.length != 0) {
        if (this.imgs.length == 1 && !this.imgs[0]?.goodsimage_sort) {
          this.final_img = this.imgs;
        } else {
          let img = JSON.parse(JSON.stringify(this.imgs));
          this.final_img = img.sort(this.sortFoo("goodsimage_sort"));
        }
      }
      for (let index = 0; index < this.final_img.length; index++) {
        if (this.final_img[index].goodsimage_type == 2) {
          this.autoplay = 0;
          break;
        }
      }
    },
    sortFoo(property) {
      return (a, b) => {
        let v1 = a[property];
        let v2 = b[property];

        if (v1 < v2) {
          return 1;
        }
        if (v1 > v2) {
          return -1;
        }
        return 0;
      };
    },
    stop() {
      this.autoplay = 0;
    },
    onChange(index) {
      this.current = index;
      // if (this.autoplay == 0) {
      //   this.autoplay = 3000;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.my-swipe {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}

.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}
video {
  width: 100%;
  height: 375px;
}
</style>

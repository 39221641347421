<template>
  <div class="goods_details">
    <h4 class="g-d-title flex-container">
      <i></i> <span>宝贝详情</span> <i></i>
    </h4>
    <div
      class="g-d-details"
      v-if="detail && detail.plate_top"
      v-html="detail.plate_top.storeplate_content"
    ></div>
    <div class="g-d-details" v-html="detail && detail.goods_body"></div>
    <div
      class="g-d-details"
      v-if="detail && detail.plate_bottom"
      v-html="detail.plate_bottom.storeplate_content"
    ></div>

    <h4 class="g-d-title flex-container mb" @click="gotoTop">
      <i></i> <span>暂无更多内容（返回顶部）</span> <i></i>
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
    },
  },

  mounted() {
    this.$nextTick(() => {
      /***
       * 图片跳转
       */
      // setTimeout(() => {
      //   let tag = document.getElementsByTagName("img");
      //   for (let index = 0; index < tag.length; index++) {
      //     if (tag[index]?.dataset.href) {
      //       console.log();
      //       tag[index].onclick = function() {
      //         window.open(decodeURIComponent(tag[index]?.dataset.href));
      //       };
      //     }
      //   }
      // }, 500);
    });
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0 });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "~@css/mixin.scss";
.mb {
  margin: rem(50) 0 rem(150);
}
.goods_details {
  .g-d-title {
    padding: rem(40) 0;
    justify-content: center;
    align-items: center;

    i {
      width: rem(80);
      height: 1px;
      background: rgba(128, 128, 128, 0.6);
    }
    span {
      color: #808080;
      margin: 0 rem(30);
    }
  }
  .g-d-details {
    width: 100%;
    p,
    img {
      max-width: 100%;
    }
  }
}
</style>
